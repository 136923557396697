import React from "react";
import Layout from "../components/layout";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import ContactForm from "../components/contactForm";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { parseNestedHTML } from "../Utils";
import AddressAndTransport from "../components/AddressAndTransport";
import HTMLReactParser from "html-react-parser";
import SafeHtmlParser from "../components/safe-html-parser";
import GroupForm from "../components/group-form";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const TeamBuildingActivitiesEastbourne = () => {
	const data = useStaticQuery(graphql`
		query {
			wpCompanyGroup {
				companyGroups {
					address {
						btnText
						location {
							lat
							lng
						}
						title
					}
					foodsAndDrinks {
						body
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						title
					}
					heroSection {
						btnText
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						title
					}
					parkingArea {
						body
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					seatingArea {
						body
						btnText
						title
					}
					teamBuilding {
						body
						btnText
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						title
					}
					vehicleStatus {
						body
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						title
					}
					whyChooseEastbourne {
						body
						btnText
						title
					}
				}
				seoFields {
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const CompanyGroup = data.wpCompanyGroup.companyGroups;
	const seoFields = data.wpCompanyGroup.seoFields;
	// console.log(CompanyGroup);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Team Building Activities Eastbourne",
				item: {
					url: `${siteUrl}/team-building-activities-eastbourne`,
					id: `${siteUrl}/team-building-activities-eastbourne`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script src="https://www.google.com/recaptcha/api.js" async defer />
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/team-building-activities-eastbourne`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={CompanyGroup.heroSection.title}
												/>
											</div>
											<Button
												href="#form"
												className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
											>
												{CompanyGroup.heroSection.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="slider-container">
							<Slider {...settings}>
								{CompanyGroup.heroSection.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>
				<section className="pt-5 pt-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase">
									{CompanyGroup.teamBuilding.title}
								</h2>

								<div>
									<SafeHtmlParser
										htmlContent={CompanyGroup.teamBuilding.body}
									/>
								</div>
								<Button
									href="#form"
									className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
								>
									{CompanyGroup.teamBuilding.btnText}
								</Button>

								<div className="w-100 py-5">
									<GatsbyImage
										image={
											CompanyGroup.teamBuilding.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={CompanyGroup.teamBuilding.image?.node?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8}>
								<Stack gap={3} className="align-items-center text-center">
									<h2 className="display-5 pb-0 text-uppercase">
										{CompanyGroup.seatingArea.title}
									</h2>

									<div>
										<SafeHtmlParser
											htmlContent={CompanyGroup.seatingArea.body}
										/>
									</div>
									<Button
										href="#form"
										className="text-white text-uppercase w-100 w-md-auto mt-2 fs-5 px-4 py-2"
									>
										{CompanyGroup.seatingArea.btnText}
									</Button>
								</Stack>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<Container>
						<Row className="g-5">
							<Col lg={6}>
								<div
									className="p-3 h-100"
									style={{ backgroundColor: "#181819", borderRadius: 8 }}
								>
									<GatsbyImage
										image={
											CompanyGroup.foodsAndDrinks.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										style={{
											borderRadius: 12,
											height: "250px",
										}}
										alt={CompanyGroup.foodsAndDrinks.image?.node?.altText}
									/>
									<h3 className=" mt-3 pt-2 text-uppercase">
										{CompanyGroup.foodsAndDrinks.title}
									</h3>
									<p className="text-white pt-2 pb-0 fs-5 ">
										{CompanyGroup.foodsAndDrinks.body}
									</p>
								</div>
							</Col>
							<Col lg={6}>
								<div
									className="p-3 h-100"
									style={{ backgroundColor: "#181819", borderRadius: 8 }}
								>
									<GatsbyImage
										image={
											CompanyGroup.parkingArea.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										style={{
											borderRadius: 12,
											height: "250px",
										}}
										alt={CompanyGroup.parkingArea.image?.node?.altText}
									/>
									<h3 className=" mt-3 pt-2 text-uppercase">
										{CompanyGroup.parkingArea.title}
									</h3>
									<p className="text-white pt-2 pb-0 fs-5 ">
										{CompanyGroup.parkingArea.body}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8}>
								<Stack gap={3} className="align-items-center text-center">
									<h2 className="display-5 pb-0 text-uppercase">
										{CompanyGroup.whyChooseEastbourne.title}
									</h2>

									<div>
										<SafeHtmlParser
											htmlContent={CompanyGroup.whyChooseEastbourne.body}
										/>
									</div>
									<Button
										href="#form"
										className="text-white text-uppercase w-100 w-md-auto mt-2 fs-5 px-4 py-2"
									>
										{CompanyGroup.whyChooseEastbourne.btnText}
									</Button>
								</Stack>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<AddressAndTransport
						buttonLink="#form"
						address={CompanyGroup.address}
						vehiclesStatus={CompanyGroup.vehicleStatus}
					/>
				</section>
				<section id="form" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase text-center">
									Get in Touch
								</h2>

								<GroupForm page="Team Building Activities Eastbourne" />
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</div>
	);
};

export default TeamBuildingActivitiesEastbourne;
